.interactable-grid {
    display: grid;
    grid-template-columns: repeat(10, 25px);
    grid-template-rows: repeat(10, 25px);
    grid-gap: 0px;
}

.grid-row {
    display: flex;
}

.cell {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
}
.wall {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: rgb(7, 2, 22);
}

.air {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: rgb(245, 245, 240);
}

.blue-block {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: rgb(28, 48, 224);
}

.blue-goal {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: rgb(119, 32, 233);
}

.orange-block {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: rgb(230, 109, 40);
}

.orange-goal {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: rgb(231, 39, 199);
}

.cell:hover {
    background-color: #f0f0f0;
}

.center {
    display: flex;
    justify-content: center;
}